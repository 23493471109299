@import "core/_common.scss";

.LoginContainer {
  display: flex;
  justify-content: center;

  form {
    background: var(--background-color-1);
    border-radius: 1em;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;

    h1 {
      margin: 0;
    }

    .error {
      color: var(--danger);
      margin-bottom: 0.5rem;
    }

    .passwordInput {
      position: relative;
      width: 100%;
      padding-right: 3rem;
    }

    .passwordButton {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 2rem;
    }

    .lostPassword {
      align-self: flex-start;
      font-size: small;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .helpAccess {
      align-self: center;
      font-size: small;
      margin-top: 1rem;
    }

    .loginButton {
      align-self: center;
      width: 50%;
      padding: 0.5rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      .fa-sign-in-alt {
        margin-right: 0.5rem;
      }
    }

    .FormInput {
      width: 100%;
      margin-bottom: 1rem;

      input {
        border: 1px solid var(--darkgray);
        border-radius: var(--border-radius);
        background: var(--white);
        min-height: 2rem;
        width: 100%;
        transition: all 0.3s;
        font-size: 1rem;
        height: 2rem;
        padding: 0.25rem 0.5rem;
      }
    }

    .separator {
      margin: 1rem 0;
      text-align: center;
      width: 100%;
      border-bottom: 1px solid var(--darkgray);
      line-height: 0.1em;
      color: var(--lightgray);
      position: relative;
    }

    .separator::after {
      content: "Ou prossiga com:";
      background: var(--background-color-1);
      padding: 0 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -0.75rem;
    }

    .googleLoginButton {
      background: white;
      color: #757575;
      width: 90%;
      text-align: center;
      padding: 0.5rem;
      border-radius: var(--border-radius);
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ddd;
      transition: all 0.3s;

      &:hover {
        background: #f7f7f7;
        transform: scale(1.05);
      }

      .googleLogo {
        height: 20px;
        margin-right: 0.5rem;
      }
    }
  }
}
