@import "core/_colors.scss";

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }
  * {
    box-sizing: border-box;
    transition: none !important;
  }
  body > *:not(#print-root) {
    display: none;
  }
  body,
  #print-root {
    color-adjust: exact;
  }
  #print-root {
    --blue: $darkblue;
    --darkblue: $darkgray;
    --lightgray: white;
    --gray: black;
    --darkgray: black;
    --white: white;
    --black: black;
  }

  .page {
    margin: 0;
    height: 100vh;
    width: 100vw !important;
    font-family: Arial;
    display: flex;
    flex-wrap: wrap;
    padding: 7mm;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    page-break-after: always;
    overflow: hidden;

    :first-of-type .tag {
      transform: translateY(0.5mm);
    }
    :last-child {
      height: auto;
    }
  }

  .tag-wrapper {
    position: relative;
    width: 50%;
    height: 28.3mm;
    min-height: 28.3mm;
    max-height: 28.3mm;

    .tag {
      position: absolute;
      top: 3mm;
      left: 3mm;
      right: 3mm;
      bottom: 3mm;
      border: 0.4mm solid #ccc;
      display: grid;
      padding: 2mm;
      border-radius: 2mm;
      gap: 1mm;
      grid-template:
        "levelGrade site" 1fr
        "name name" 2fr
        "credentials credentials" 3fr
        / 1fr 1fr;
      overflow: hidden;

      .name {
        grid-area: name;
        font-weight: var(--bold);
        border-bottom: 0.4mm solid #ccc;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 11pt;
      }

      .levelGrade {
        grid-area: levelGrade;
        font-size: 8pt;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .site {
        grid-area: site;
        text-align: right;
        font-size: 8pt;
      }

      .credentials {
        grid-area: credentials;
        display: flex;
        flex-direction: row;

        h4 {
          margin: 0;
          padding: 0;
          font-size: 10pt;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 11pt;
        }

        .password {
          flex-shrink: 1;
          margin-left: 3mm;
        }
      }

      .logo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        filter: grayscale(1) opacity(0.15);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 2mm;

        img {
          height: 50%;
        }
      }
    }
  }
}

@media not print {
  #print-root {
    display: none;
  }
}
